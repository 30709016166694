<template>
    <div class="popup-overlay" v-if="isVisible">
      <div class="popup-container">
        <button class="close-button" @click="closePopup">✕</button>
        <div class="popup-content">
          <div class="popup-image">
            <img src="/assets/images/gym-chair/10.png" alt="Chair Image" />
          </div>
          <div class="popup-text">
            <h2>SINIRLI TEKLİF %10 İNDİRİM</h2>
            <p>
              Şimdi mail adresinizi girin ve mail adresinize size özel %10 indirim tanımlayalım. Kupon kodunuz mail adresinize gönderilir. Bu teklif sadece 7 gün geçerlidir.
            </p>
            <form @submit.prevent="submitEmail">
              <input
                type="email"
                v-model="email"
                placeholder="Email"
                required
              />
              <button type="submit">İNDİRİM AL</button>
            </form>
            <button class="no-thanks" @click="closePopup">TEŞEKKÜRLER, İSTEMİYORUM</button>
          </div>
        </div>
      </div>
    </div>
    <Toast ref="toast" />
    <ToastError ref="toastError" />


  </template>
  
  <script>
  import Toast from './Toast.vue';
  import ToastError from './ToastError.vue';
  export default {
    components: { Toast, ToastError },
    data() {
      return {
        isVisible: false,
        email: "",
      };
    },
    mounted() {
      this.checkPopupVisibility();
    },
    methods: {
      checkPopupVisibility() {
        const popupClosedTime = localStorage.getItem("popupClosedTime");
        if (popupClosedTime) {
          const now = new Date().getTime();
          const oneDay = 24 * 60 * 60 * 1000;
          if (now - popupClosedTime < oneDay) {
            this.isVisible = false;
            return;
          }
        }
        this.isVisible = true;
      },
      closePopup() {
        this.isVisible = false;
        const now = new Date().getTime();
        localStorage.setItem("popupClosedTime", now);
      },
      submitEmail() {
  if (this.validateEmail(this.email)) {
    fetch("https://ohupfitness.com.tr/inc/save_email.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({ email: this.email }).toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
            this.$refs.toast.showToast("Teşekkürler! İndirim kodunuz mail adresinize gönderildi.");
          this.closePopup();
        } else {
            this.$refs.toastError.showToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Hata:", error);
        this.$refs.toastError.showToast("Bir hata oluştu, lütfen tekrar deneyin.");
      });
  } else {
    this.$refs.toastError.showToast("Lütfen geçerli bir mail adresi girin.");
  }
},

      validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
      },
    },
  };
  </script>
  
  <style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
  
  .popup-container {
    background-color: white;
    border-radius: 12px;
    display: flex;
    max-width: 800px;
    width: 90%;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    animation: slide-up 0.3s ease-out;
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .popup-content {
    display: flex;
    width: 100%;
  }
  
  .popup-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .popup-text {
    flex: 1;
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .popup-text h2 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .popup-text p {
    font-size: 14px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .popup-text form {
  display: flex;
  flex-direction: column;
}

.popup-text input {
  padding: 10px 0;
  font-size: 14px;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease;
  margin-bottom: 20px;
}

.popup-text input:focus {
  border-bottom: 2px solid #000;
}

.popup-text input::placeholder {
  color: #aaa;
  font-size: 14px;
}

  
  .popup-text button {
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    background-color: black;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popup-text button:hover {
    background-color: #444;
  }
  
  .no-thanks {
    margin-top: 10px;
    background: none !important;
    border: none;
    color: #000 !important;
    cursor: pointer;
  }
  
  .no-thanks:hover {
    text-decoration: underline;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
  }

  @media (max-width:768px) {
    .popup-content{
        flex-direction: column;
    }

    .popup-image img{
        height: 400px;
    }

  }
  </style>
  