<template>
    <div v-if="isVisible" class="toast">
        <i class="fa-solid fa-x"></i>
      <p>{{ message }}</p>
      <button @click="hideToast">×</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isVisible: false,
        message: "",
      };
    },
    methods: {
      showToast(msg) {
        this.message = msg;
        this.isVisible = true;
        setTimeout(() => {
          this.isVisible = false;
        }, 5000); 
      },
      hideToast() {
        this.isVisible = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .toast {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #a90000;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    z-index: 1000;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .toast i {
    font-size: 1.5rem;
    color: #fff !important;
  }
  
  .toast button {
    background: none;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>
  