<script setup>
import { useRoute } from 'vue-router';
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import CookieConsent from './components/CookieConsent.vue'
import Popup from './components/Popup.vue';
const route = useRoute();

const noHeaderFooterRoutes = ['/checkout'];
</script>

<template>
  <div id="app-container">
    <Header v-if="!noHeaderFooterRoutes.includes(route.path)" />
    <main>
      <router-view />
      <Popup />
    </main>
    <Footer v-if="!noHeaderFooterRoutes.includes(route.path)" />
    <CookieConsent />
  </div>
</template>

<style scoped>



</style>
